export const couponsManager = {
  isFetching: false,
  data: null as any,
  fetchPromise: null as Promise<any> | null
}

export async function fetchCouponsOnce(authToken: string): Promise<any> {
  // Return fetched data if exists.
  if (
    couponsManager.data?.coupons?.length ||
    couponsManager.data?.giftCards?.length
  ) {
    return couponsManager.data
  }

  // Return a promise if we are still fetching user coupons.
  if (couponsManager.isFetching && couponsManager.fetchPromise) {
    return couponsManager.fetchPromise
  }

  // Let's do the real coupons fetching at this point.
  couponsManager.isFetching = true
  couponsManager.fetchPromise = $fetch(`/api/account/getCoupons/${authToken}`)
    .then((response) => {
      couponsManager.data = response
      couponsManager.isFetching = false
      couponsManager.fetchPromise = null
      return couponsManager.data
    })
    .catch((error) => {
      couponsManager.isFetching = false
      couponsManager.fetchPromise = null
      throw error
    })

  return couponsManager.fetchPromise
}
